@import "../../Main.module.scss";

.menu_dropdown {
    @include hover-cursor-pointer;
    text-align: center;
    height: 100%;

    &_wrapper {
        position: relative;
        height: 100%;
    }
    
    &_arrow_icon {
        padding: 0 0 0 10px;
        float: right;
        position: relative;
        z-index: 1;
    }
    
    &_arrow_icon_menu {
        padding: 4px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: white;
        background-color: transparent;
        width: 28px;
        height: 100%;
        &:hover {
            border-radius: 0px 40px 40px 0px;
            background-color: rgba(224, 238, 248, 0.3);
        }
    }
    
    &_arrow_icon_menu_no_hover {
        padding: 4px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: white;
        background-color: transparent;
        height: 100%;
    }
    
    &_arrow_icon_menu_no_hover_selected {
        padding: 4px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: $primary-text-color;
        background-color: white;
        border-radius: 0px 40px 40px 0px;
        width: 28px;
        height: 100%;
    }
    
    &_arrow_icon_selected {
        padding: 3px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: $primary-text-color;
        background-color: white;
        border-radius: 0px 40px 40px 0px;
        height: 100%;
    }
    
    .menu_dropdown {
        border: none;
    }
    
    &_children {
        right: 0;
        color: $primary-text-color;
        position: absolute;
        z-index: 2;
        font-size: 14px;
        font-weight: 500;
        background-color: $plainWhite;
        box-sizing: border-box;
        top: 35px;
        border: 1px solid $stonex-blue-near-white;
        box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        width: max-content !important;
        min-width: 240px;
        height: auto;
        overflow-y: auto;
        left: 0px;
        padding-top: 16px;
        padding-bottom: 12px;
    }
    
    &_single_child {
        line-height: 22px;
        padding: 2px 0px 2px 28px;
        font-size: 14px;
    }
    
    &_single_child:hover {
        cursor: pointer;
        background: $stonex-blue-near-white;
    }
    
    &_sub_header {
        line-height: 24px;
        padding: 6px 0px 6px 16px;
        text-transform: uppercase;
        font-size: 14px;
    }
    
    &_sub_header:hover {
        cursor: pointer;
        background: $stonex-blue-near-white;
    }

    &_active_dropdown_icon {
        background-color: white;
        color: black;
        border-radius: 0px 40px 40px 0px;
    }
    
    &_menu_item_divider {
        border: 0.1px solid $stonex-light-gray-blue;
    }
}


