@import '../../../../Main.module.scss';

.input {
 

    &_dropdown_flex {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &_selection_dropdown {
        height: 400px;
    }

    &_input_component_h3 {
        margin-top: 0;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    &_input_container {
        font-family: $application-font-family;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    &_input_component {
        font-family: $application-font-family;
        border: 1px solid $stonex-light-gray-blue;
        width: 100%;
        max-width: 451px;
        border-radius: 3px;
        padding-right: 16px;
        padding: 10px 16px 10px 16px;
    }

    &_search_area {
        display: flex;
        justify-content: flex-start;

        & textarea {
            border-color: $stonex-light-gray-blue;
            padding: 10px 16px;
            font-size: 14px;
            min-height: 120px;
        }
    }

    &_input_component_error {
        font-family: $application-font-family;
        border: 1px solid $input-error-border;
        background-color: $input-error-background;
        width: 100%;
        max-width: 451px;
        border-radius: 3px;
        padding-right: 16px;
        padding: 10px 16px 10px 16px;
    }

    /* Chrome, Safari, Edge, Opera */
    &_input_component_error::-webkit-outer-spin-button,
    &_input_component_error::-webkit-inner-spin-button,
    &_input_component::-webkit-outer-spin-button,
    &_input_component::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &_input_component_error[type='number'],
    &_input_component[type='number'] {
        -moz-appearance: textfield;
    }

    &_error_message {
        font-family: $application-font-family;
        color: $stonex-negative-red;
        margin-top: 5px;
        margin-bottom: 0;
    }

    &_dropdown_component {
        border: 1px solid $stonex-light-gray-blue;
        width: 100%;
        max-width: 451px;
        padding: 10px 8px 10px 16px;
        border-radius: 3px;

        &_dropdown_style {
            justify-content: space-between;
            align-items: center;
        }
    }

    &_auto_complete_error div,
    &_auto_complete div,
    &_input_component,
    &_input_component_error {
        height: 40px;
    }

    &_auto_complete_error div div,
    &_auto_complete div div {
        padding: 0 0 0 9px !important;
    }

    &_auto_complete_error,
    &_auto_complete,
    &_base_dropdown {
        margin-top: 0px;
        font-size: 14px;
        outline: none;
        width: 100%;
        max-width: 451px;
        border-radius: 3px;
        border: 1px solid transparent;

        legend {
            width: 0%;
        }

        &:hover {
            outline: none;
        }

        &_company_header {
            margin-top: 0;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 600;
        }

        &_container div div div input {
            padding: 0px !important;
        }
    }

    &_auto_complete_error {
        border-radius: 5px;
        background-color: $input-error-background;
        border: 1px solid $input-error-border;
    }

    &_file_upload {
        &_upload_button {
            @include justify-and-align-flex-items-center;
            display: flex;
            padding: 11px 16px;
            text-transform: uppercase;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.5px;
            background: $plainWhite;
            border: 1px solid $stonex-light-gray-blue;
            color: $primary-text-color;

            &:hover {
                @include hover-cursor-pointer;

                &:disabled {
                    cursor: default;
                }
            }

            &:disabled {
                border: 1px solid $stonex-light-gray-blue;
                background: $deactivated-block-color;
                color: $stonex-disabled-light-gray;
            }
        }

        &_drag_drop {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 640px;
            height: 348px;
            border: 5px dashed $stonex-disabled-light-gray;
            gap: 12px;

            h3 {
                font-weight: 600;
                font-size: 18px;
            }

            &_content_holder {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
            }
        }

        &_drag_overlay {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.034);
            width: 640px;
            height: 348px;
        }
    }

    &_password_visibility_icon {
        @include hover-cursor-pointer;
        position: absolute;
        left: 270px;
        margin-top: 6px;
        background-color: transparent;
        border: none;
    }

    &_search_area {
        width: 100%;
    }

    &_tooltip_wrapper {
        justify-content: center;
        display: flex;
    }

    &_tooltip {
        width: 20px;
        margin-left: 10px;
        position: relative;
        top: -3px;
    }

    &_tooltip_middle {
        width: 20px;
        vertical-align: middle;
        margin-left: 10px;
    }

    &_switch_with_label {
        cursor: pointer;
    }
}

.date_picker {
    position: relative;

    &_icon_button {
        position: absolute;
        top: 2px;
        right: 0;
    }

    &_calendar button:hover {
        background-color: transparent;

        & span {
            border: 1px solid $stonex-light-gray-blue;
        }
    }

    &_calendar button[aria-selected='true'] {
        background-color: $stonex-action-blue !important;
        color: $plainWhite;
    }

    & svg {
        color: $primary-text-color;
    }
}
