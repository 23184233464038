@import "../../Main.module.scss";

.menu {

  &_file_selector {
    &_commodity_btn_full {
      font-size: 14px;
      font-weight: 700;
      margin-right: 5px;
      border-radius: 40px;
      flex-direction: row;
      align-items: center;
      padding: 3px 16px;
      position: static;
      height: 32px;
      background: transparent;
      flex: none;
      order: 1;
      flex-grow: 0;
      border: 1px solid transparent;
      color: $plainWhite;
      &:hover {
        @include hover-cursor-pointer;
        border: 1px solid $stonex-sky-blue;
        background-color: rgba(224, 238, 248, 0.3);
      }
    }
    &_no_commodity_btn {
      font-size: 14px;
      color: $plainWhite;
      background: transparent;
      border-radius: 40px 0px 0px 40px;
      border: 1px solid transparent;
      padding: 3px 4px 3px 16px;
      font-weight: 700;
      cursor: pointer;
      height: 32px;
    }

    &_price_commodity_btn {
      font-weight: 700;
      font-size: 14px;
      border-radius: 40px 0px 0px 40px;
      flex-direction: row;
      align-items: center;
      padding: 3px 4px 3px 16px;
      background: transparent;
      border: 1px solid transparent;
      color: $plainWhite;
      cursor: pointer;
      &:hover {
        border-right: 1px solid white;
        background-color: rgba(224, 238, 248, 0.3);
      }
    }
  
    &_flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 32px;
    }
    
    &_exchanges_button, &_exchanges_button_selected {
      @include hover-cursor-pointer;
      @include justify-and-align-flex-items-center;
      font-size: 14px;
      color: white;
      display: flex;
      background: $menu-file-selector-theme;
      border: none;
      border-right: 1px solid $stonex-light-gray-blue;
      display: flex;
      flex-direction: row;
      padding: 8px 12px;
      margin-left: 8px;
      height: 34px;
      min-width: 54px;
      &:first-child {
        margin-left: 0px;
      }
    }
  
    &_exchanges_button {
      background-color:  $stonex-submenu-gray-blue;
      color: $primary-text-color;
    }
    
    &_exchanges_button, &_exchanges_button_selected {
      border-radius: 3px 3px 0 0;
    }
    
    &_exchange_butttons {
      position: relative;
      padding: 9px 0px 9px 12px;
      background-color: $menu-file-selector-theme;
      color: white;
      margin-bottom: 20px;
      border-radius: 0px 3px 3px 3px;
    }
  }

  &_custom {
    
    &_wrapper {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 5px;
        border: 1px solid transparent;
        &:hover {
          border-radius: 28px;
          border: 1px solid $stonex-sky-blue;
        }
    }
    
    &_wrapper_selected {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 5px;
        border: 1px solid transparent;
    }
    
    &_wrapper_no_commodity {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 5px;
      border: 1px solid transparent;
      border-radius: 28px;
      &:hover {
        border: 1px solid $stonex-sky-blue;
        background-color: rgba(224, 238, 248, 0.3);
      }
    }
    
    &_wrapper_no_commodity_selected {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 5px;
      border: 1px solid transparent;
      background-color: white;
      &:hover {
        border-radius: 28px;
        border: 1px solid $stonex-sky-blue;
        background-color: rgba(224, 238, 248, 0.3);
      }
    }

    &_btn {
      @include stonex-bold-font;
      @include hover-cursor-pointer;
      background: white;
      font-size: 16px;
      border-radius: 40px 0px 0px 40px;
      border: 1px solid transparent;
      padding: 3px 4px 3px 16px;
      border-right: 1px solid $stonex-disabled-light-gray;
    }

    &_btn_no_right_border {
      height: 100%;
      font-size: 16px;
      @include hover-cursor-pointer;
      color: $primary-text-color;
      background: white;
      border-radius: 40px 0px 0px 40px;
      border: 1px solid transparent;
      padding: 3px 4px 3px 16px;
      font-weight: 700;
    }
    
    &_btn_full {
      @include stonex-bold-font;
      @include hover-cursor-pointer;
      background: white;
      border-radius: 40px 0px 0px 40px;
      border: none;
      padding: 3px 4px 3px 16px;
      height: 32px;
      border-right: 1px solid $stonex-disabled-light-gray;
    }
    
    &_btn_full_commodity {
      @include hover-cursor-pointer;
      @include stonex-bold-font;
      font-size: 14px;
      background: $plainWhite;
      border-radius: 40px;
      margin-right: 5px;
      border: none;
      padding: 4px 16px 4px 16px;
      height: 32px;
      border-right: 1px solid $stonex-disabled-light-gray;
    }

  }

  &_file_drp {
    background-color: $plainWhite;
    margin-left: -3px;
  }

  &_breadcrumb {
    @include stonex-bold-font;
    font-size: 14px; 
    color: $stonex-medium-gray;
    margin-bottom: 10px;
  }

  &_right_side_of_breadcrumb {
    position: absolute;
    right: 0;
    bottom: 0;
    width: max-content;
  }

}



