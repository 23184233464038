@import '../../../../Main.module.scss';

.button {
    &_link_white {
        color: $plainWhite;
        &:hover {
            color: $plainWhite;
        }
    }

    &_link_blue {
        color: $primary-text-color;
        &:hover {
            color: $primary-text-color;
        }
    }

    &_white,
    &_blue,
    &_green {
        @include justify-and-align-flex-items-center;
        font-family: $application-font-family;
        height: 40px;
        display: flex;
        padding: 11px 16px;
        text-transform: uppercase;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.07em;

        &:hover {
            @include hover-cursor-pointer;
            &:disabled {
                cursor: default;
                background: $deactivated-block-color;
            }
        }
        &:disabled {
            border: none;
            background: $deactivated-block-color;
        }
        &:disabled .button_link_blue,
        &:disabled .button_link_white {
            color: $stonex-disabled-light-gray !important;
        }
    }

    &_blue {
        color: $plainWhite;
        margin-right: 0;
        white-space: nowrap;
        background: $stonex-blue-theme;
        border: 1px solid $stonex-blue-theme;
        text-decoration: none;
        a {
            text-decoration: none;
        }
        &:hover {
            background: $stonex-blue-button-hover;
        }
    }

    &_white {
        background: $plainWhite;
        border: 1px solid $stonex-light-gray-blue;
        color: $primary-text-color;
        a {
            text-decoration: none;
        }
        &:hover {
            background: $stonex-blue-near-white;
        }
    }

    &_icon {
        margin-right: 5px;
    }
}

.text_button {
    color: $stonex-action-blue;
    cursor: pointer;
}
