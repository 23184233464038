@import '../../../../Main.module.scss';

.chart_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    &_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &_header_title {
        @include stonex-bold-font;
        line-height: 33px;
        font-size: 18px;
    }

    &_sub_header {
        width: 100%;
        display: flex;
    }

    &_header_actions_container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    &_actions_container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    &_footer {
        width: 100%;
        display: flex;
    }

    &_data_source_tag {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 6px;

        & p {
            margin: 0px;
        }
    }

    &_disclaimer {
        margin-top: 0px !important;
        color: $stonex-medium-gray;
        font-size: 10px;
        text-align: center;
    }

    &_chart_container {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        display: flex;
    }

    &_chart {
        min-width: 400px;
        height: 100%;
    }

    &_chart_with_right_side {
        display: flex;
        height: 100%;
        font-size: 14px;
        & > div:nth-child(1) {
            min-width: calc(100% - 130px);
        }
    }

    &_image_button {
        padding: 0 !important;
    }

    &_image {
        height: 24px;
        width: 24px;
        margin-left: 4px;
        @include hover-cursor-pointer;
        vertical-align: top;
    }

    &_chart_type_item img {
        margin-right: 5px;
    }
}

.chart_modal_wrapper {
    position: relative;
    width: 95%;
    height: 95%;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 110px);
    overflow-y: hidden;

    &_inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    &_close {
        text-align: right;
    }

    &_close_icon {
        height: 24px;
        width: 24px;
        margin-left: 4px;
        @include hover-cursor-pointer;
    }
}
