@import "../../../../Main.module.scss";

.margin {
    width: fit-content;

    &_actions_buttons_row {
        display: flex;
        height: 40px;
        justify-content: space-between;
        margin: 32px 0;
    }

    &_actions_save_display_area {
        color: $stonex-medium-gray;
        display: flex;
        align-items: center;
    }

    &_actions_save_display_area p, 
    &_actions_buttons button {
        margin: 0 0 0 20px;
    }

    &_actions_buttons {
        display: flex;
    }


    &_dropdown_inputs_container {
        display: flex;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px 20px 0px 20px;
        margin: 32px 0;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 3px;

        & div>div>div>div>div {
            background-color: white;
        }
    }

    &_dropdown {
        width: 240px;
        padding: 10px;
    }
}
