@import "../../Main.module.scss";

.region_menu_dropdown {
    text-align: center;
    border: 1px solid gray;
    &_wrapper {
        position: relative;
        border-radius: 0 3px 0 0;
    }
    
    &:hover {
        cursor: pointer;
    }
    
    &_arrow_icon {
        padding: 0 0 0 10px;
        float: right;
        position: relative;
        z-index: 1;
    }
    
    &_arrow_icon_menu {
        padding: 3px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: white;
        background-color: transparent;
        height: 30px;
        width: 28px;
        &:hover {
            border-left: 1px solid white;
            border-radius: 0px 40px 40px 0px;
            background-color: rgba(224, 238, 248, 0.3);
        }
    }

    &_arrow_icon_menu_no_hover {
        padding: 3px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: white;
        background-color: transparent;
        height: 30px;
        width: 28px;
    }
    
    &_arrow_icon_menu_no_hover_selected {
        padding: 3px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: $primary-text-color;
        background-color: white;
        height: 30px;
        width: 28px;
    }

    &_arrow_icon_selected {
        padding: 3px 3px 0 0px;
        float: right;
        position: relative;
        z-index: 1;
        color: $primary-text-color;
        background-color: white;
        height: 30px;
        border-radius: 0px 40px 40px 0px;
        width: 28px;
    }

    &_children {
        right: 0;
        color: $primary-text-color;
        position: absolute;
        z-index: 2;
        font-size: 14px;
        font-weight: 500;
        background-color: white;
        box-sizing: border-box;
        top: 35px;
        border: 1px solid $stonex-blue-near-white;
        box-shadow: 0px 9px 13px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        width: max-content !important;
        min-width: 150px;
        height: 250px;
        overflow-x: scroll;
        left: 0px;
        padding-top: 16px;
        padding-bottom: 12px;
    }
    
    &_single_child {
        line-height: 22px;
        padding: 2px 0px 2px 28px;
        font-size: 14px;
    }
    
    &_single_child:hover {
        cursor: pointer;
        background: $stonex-blue-near-white;
    }
    
    &_sub_header {
        line-height: 24px;
        padding: 6px 0px 6px 16px;
        font-size: 14px;
        text-align: left;
    }
    
    &_sub_header:hover {
        cursor: pointer;
        background: $stonex-blue-near-white;
    }
    
}

.subregion_menu_dropdown {
    text-align: center;
    border: 0px solid gray;
    background-color: $stonex-submenu-gray-blue;

    &_arrow_icon_menu {
        padding: 4px 0 0;
        float: right;
        position: relative;
        z-index: 1;
        color: $primary-text-color;
        background-color: $stonex-submenu-gray-blue;
        height: 34px;
        width: 28px;
        &:hover {
            border-left: 1px solid $plainWhite;
            border-radius: 0px 40px 40px 0px;
            background-color: rgba(224, 238, 248, 0.3);
        }
    }
    
    &_arrow_icon_menu_no_hover {
        padding: 4px 0 0;
        float: right;
        position: relative;
        margin-left: -2px;
        z-index: 1;
        color: $primary-text-color;
        background-color: $stonex-submenu-gray-blue;
        height: 34px;
        width: 28px;
        border-radius: 0 3px 0 0;
    }
    &_arrow_icon_menu_no_hover_selected {
        padding: 4px 0 0;
        float: right;
        position: relative;
        margin-left: -2px;
        z-index: 1;
        color: $plainWhite;
        background-color: $menu-file-selector-theme;
        height: 34px;
        width: 28px;
        border-radius: 0 3px 0 0;
    }
    &_arrow_icon_selected {
        padding: 4px 0 0;
        float: right;
        position: relative;
        z-index: 1;
        color: white;
        background-color: $menu-file-selector-theme;
        height: 34px;
        border-radius: 0px 40px 40px 0px;
        width: 28px;
    }
}


.active_dropdown_icon {
    background-color: $deactivated-block-color;
    color: black;
    border-radius: 0px 40px 40px 0px;
}

.subregion_active_dropdown_icon {
    background-color: $menu-file-selector-theme;
    color: $plainWhite;
}

.menu_items_header {
    font-weight: 700;
    line-height: 24px;
    color: $stonex-burnt-orange;
    padding-left: 16px;
    text-transform: uppercase;
    margin-top: -5px;
}

.menu_item_divider {
    border: 0.1px solid $stonex-light-gray-blue;
}
