@import '../../../../Main.module.scss';

.origins_and_destination_chart {
    text-align: center;
    margin-top: 0px;
    height: 470px;

    &_subheader {
        @include stonex-bold-font;
        bottom: 25px;
        position: relative;
        z-index: -1;
        line-height: 20px;
        text-align: center;
        font-size: 16px;
    }

    &_inner_text {
        text-align: center;
        font-size: clamp(1.5em, 2vw, 28px);
        padding: 0;
        margin: 0;
    }

    &_inner_text {
        font-family: $application-font-family;
    }
    
    &_time_override {
        font-size: 14px;
        line-height: 17px;
    }

}
