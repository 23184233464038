@import "../../../../../Main.module.scss";

.joined_button {
    &_not_selected, &_selected  {
        @include hover-cursor-pointer;
        @include justify-and-align-flex-items-center;
        font-family: $application-font-family;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        margin-top: 4px;
        margin-bottom: 4px;
        padding: 9px 12px;
        font-weight: 700;
        line-height: 14px;
        border: none;
        background-color: $deactivated-block-color;
    }
    
    &_not_selected {
        margin-right: 4px;
    }
    
    &_selected {
        margin-right: 4px;
        letter-spacing: 0.07em;
        background-color: $plainWhite;
        border-radius: 3px;
        box-shadow: 0px 0px 4px rgba(0,0,0,0.33);
    }
    

    
    &_selection_buttons {
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        background-color: $deactivated-block-color;
        border-radius: 5px;
    }

}

