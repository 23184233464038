@import '../../../Main.module.scss';

.menu_by_market {
    display: flex;
    flex-wrap: wrap;
    background-color: $menu-file-selector-theme;
    padding: 5px;
    border-radius: 3px;
    position: relative;
    bottom: 20px;

    &_button, &_button_selected {    
        @include hover-cursor-pointer;      
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: $plainWhite;
        font-size: 16px;
        margin: 5px;
        margin-left: 5px;
        border-radius: 40px;
        flex-direction: row;
        align-items: center;
        padding: 3px 16px;
        position: static;
        height: 30px;
        background: transparent;
        box-sizing: border-box;
        flex: none;
        order: 1;
        flex-grow: 0;
        border: 1px solid transparent;
    }

    &_button_selected {
        background-color: $plainWhite;
        color: $primary-text-color;
    }

    &_tabs {
        display: flex;
    }

    &_tab, &_tab_selected {
        @include hover-cursor-pointer;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 3px 3px 0 0;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        margin-right: 8px;
        height: 34px;
        min-width: 54px;
    }

    &_tab {
        background: $stonex-submenu-gray-blue;
    }

    &_tab_selected {
        background: $menu-file-selector-theme;
        color: white;
    }
        
}