@import "../../../../../Main.module.scss";

.margin_table {
    &_row {
        display: inline-flex;
    }

    &_header {
        background-color: $stonex-off-white;
        height: 32px;
        width: 124px;
        border-right: 1px solid gray;
        display: flex;        
        align-items: center;
        justify-content: right;
        padding-right: 16px;
    }

    &_cell_weight, &_cell_percent {
        height: 32px;
        width: 124px;
        display: flex;        
        align-items: center;
        justify-content: right;
        padding-right: 16px;
    }

    &_table_wrapper {
        position: relative;
    }

    &_hidden_cell_1 {
        height: 32px;
        position: relative;
        top: 20px;
        width: 1116px;
        margin-bottom: -32px;

        & p {
            justify-self: flex-end;
            text-align: center;
            position: relative;
            left: 100px;
            bottom: 20px;
            width: 200px;
        }
    }

    &_hidden_cell {
        height: 32px;
        position: relative;
        top: 20px;
        width: 744px;

        & p {
            justify-self: flex-end;
            text-align: center;
            position: relative;
            left: 100px;
            bottom: 20px;
            width: 200px;
        }
    }

    &_header:nth-of-type(6), &_header:nth-of-type(9), 
    &_cell_percent:nth-of-type(6), &_cell_percent:nth-of-type(9), 
    &_hidden_cell, &_hidden_cell_1  {
        border-right: 2px solid black;
    }

    &_date_pickers {
        display: flex;
        justify-self: right;
        margin-bottom: 16px;
    }

    &_date_picker_margin {
        &:first-of-type {
            width: 100%;
        }
        &:nth-of-type(2) {

            margin: 0 10px;
        }
        
        text-align: center;
        align-self: baseline;
    }
}