// Application level themes.
$application-font-family: 'Mulish', 'Helvetica', 'Arial', sans-serif;
$primary-text-color: #333333;
$plainWhite: #ffffff;
$black: #000000;
$menu-file-selector-theme: #1a2e49;
$stonex-blue-theme: #2371c4;
$stonex-boxshadow-black: #00000020;
$stonex-primary-blue: #2371c4;
$stonex-snackbar-green: #308761;
$stonex-negative-red: #eb0000;
$stonex-positive-green: #078a04;
$stonex-action-blue: #2371c4;
$stonex-blue-button-hover: #0d1775;

// Calculators
$stonex-valorization-blue: #1a5593;
$stonex-valorization-button-border: #4d94df;

// Indicators.
$positive-light-green: #cff6d4;

// Market indicators.
$stonex-bright-green-text: #078a04;
$stonex-bright-red-text: #f14444;
$very-bearish-color: #da8e17;
$bearish-color: #f7c576;
$neutral-color: #f3f4f4;
$bullish-color: #a7c6e7;
$bullish-color-alpha: #a7c6e775;
$very-bullish-color: #2371c4;
$alternative-neutral-color: #e7e9e9;
$alternative-neutral-color-level-2: #d8dcdc;
$alternative-neutral-color-level-3: #cccece;

$alternative-neutral-color-alpha: #e7e9e975;
$alternative-neutral-text-color: #77787a;

// Sidebar.
$sidebar-element-hover: #2b4362;
$sidebar-background: #040c17;
$stonex-sidebar-colorblock: #7aadd4;
$stonex-sidebar-selected: #425a7c;

// Table badges.
$expired-block-color: #fbcccc;
$expiring-block-color: #e0d253;
$deactivated-block-color: #e7e9e9;
$pending-block-color: #fcecd1;
$trial-block-color: #233d93;
$active-block-color: #cde8cd;
$inactive-block-color: #a9acb0;

// Table row styles.
$table-row-valid: #e8f8ed;
$table-row-invalid: #fce3e4;
$table-row-dark: #f3f4f4;

// Table - other.
$ag-row-hover: #e0eef8;
$ag-row-selected: #dbe5ec;
$ag-hover-yellow: #fffad3;
$stonex-sparkline-primary: #1a2e49;
$stonex-active-license: #ecf6ec;
$transparent: transparent;

// Wrappers, inputs, borders, and miscellaneous.
$footer-gray-border: #e5e5e5;
$stonex-gray-blue-border: #b9bfc7;
$input-active-state: #0a3254;
$negative-light-red: #fedadb;
$neutral-light-blue: #cfe3f6;
$input-error-border: #b2292e;
$input-error-background: #fcf6f6;
$stonex-blue-near-white: #f3f4f4;
$stonex-light-gray: #babfc7;
$stonex-light-gray-blue: #cfd3d3;
$stonex-light-gray-white: #f7f7f7;
$stonex-light-gray-secondary: #dcdcdc;
$stonex-medium-gray: #77787a;
$stonex-off-white: #f9f9f9;
$stonex-dark-gray: #555759;
$stonex-disabled-light-gray: #b0b3b3;
$stonex-submenu-gray-blue: #c9d2df;
$stonex-sky-blue: #dfe6ef;
$stonex-sky-blue-translucent: #dfe6ef72;
$session-page-red-gray: #d6d6d6;
$stonex-gray-red-hue: #e9e9e9;
$stonex-burnt-orange: #fa7b17;

// Other styles.
$bold: bold;
$oktaUrl: 'https://ok12static.oktacdn.com/fs/bco/7/fs01aoufejDqkhbpQ5d7';

// Export only - chart colors.
$chart-color-1: #fa7b17;
$chart-color-2: #73a5f7;
$chart-color-3: #8430ce;
$chart-color-4: #2175ff;
$chart-color-5: #2c893d;
$chart-color-6: #fbe204;
$chart-color-7: #ef6022;
$chart-color-8: #02b389;
$chart-color-9: #0f55c9;
$chart-color-10: #af76e1;
$chart-color-11: #e3a900;
$chart-color-12: #ce4c14;
$chart-color-13: #02a37c;
$chart-color-14: #0e4db2;
$chart-color-15: #6912b5;
$chart-color-16: #fa7b17;
$chart-color-17: #004580;
$chart-color-18: #6dcdcd;
$chart-color-19: #fbbc04;

$value-matrix-chart-color-1: #000000;
$value-matrix-chart-color-2: #8430CE;
$value-matrix-chart-color-3: #E74500;
$value-matrix-chart-color-4: #80004C;

// Export only - highchart colors.
$highchart-color-1: #66A0FF;
$highchart-color-2: #61C2AB;
$highchart-color-3: #B082D8;
$highchart-color-4: #FCDC7E;
$highchart-color-5: #668DCD;
$highchart-color-6: #FBAE71;
$highchart-color-7: #8FB7F9;
$highchart-color-8: #418377;
$highchart-color-9: #DC9F84;
$highchart-color-10: #81D1D7;
$highchart-color-11: #7E8C9B;
$highchart-color-12: #6dcdcd;
$highchart-color-13: #2a67cd;

$chart-bar-forecast-color-1: rgba(42, 103, 205, 0.5);
$chart-bar-forecast-color-2: rgba(157, 89, 216, 0.5);
$chart-bar-forecast-color-3: rgba(77, 145, 255, 0.5);

// Deciles.
$ninety-to-one-hundred-decile-chart: #b6b6b6;
$eighty-to-ninety-decile-chart: #c5c5c5;
$seventy-to-eighty-decile-chart: #d3d3d3;
$sixty-to-seventy-decile-chart: #e2e2e2;
$fifty-to-sixty-decile-chart: #f0f0f0;

$data-decile-1: #c4c7c7;
$data-decile-2: #e2e2e2;
$data-decile-3: #f0f0f0;
$data-decile-4: #e2e2e2;
$data-decile-5: #c4c7c7;
$data-decile-alpha-1: #c4c7c790;
$data-decile-alpha-2: #e2e2e290;
$data-decile-alpha-3: #f0f0f090;
$data-decile-alpha-4: #e2e2e290;
$data-decile-alpha-5: #c4c7c790;


$ninety-to-one-hundred-decile: #f29e1a;
$eighty-to-ninety-decile: #f5b148;
$seventy-to-eighty-decile: #f7c576;
$sixty-to-seventy-decile: #fad8a3;
$fifty-to-sixty-decile: #fcecd1;
$fourty-to-fifty-decile: #d3e3f3;
$thirty-to-fourty-decile: #a7c6e7;
$twenty-to-thirty-decile: #7baadc;
$ten-to-twenty-decile: #4f8dd0;
$zero-to-ten-decile: #2371c4;
$ninety-to-one-hundred-decile-alpha: #f29e1a75;
$eighty-to-ninety-decile-alpha: #f5b14875;
$seventy-to-eighty-decile-alpha: #f7c57675;
$sixty-to-seventy-decile-alpha: #fad8a375;
$fifty-to-sixty-decile-alpha: #fcecd175;
$fourty-to-fifty-decile-alpha: #d3e3f375;
$thirty-to-fourty-decile-alpha: #a7c6e775;
$twenty-to-thirty-decile-alpha: #7baadc75;
$ten-to-twenty-decile-alpha: #4f8dd075;
$zero-to-ten-decile-alpha: #2371c475;
$white-overlay: #ffffffb3;
$decile-median-line: #004580;

// Margin colors.
$margin-color-scale-zero-to-five: #ff0000;
$margin-color-scale-five-to-ten: #ff5e02;
$margin-color-scale-ten-to-fifteen: #ff7914;
$margin-color-scale-fifteen-to-twenty: #ff8e32;
$margin-color-scale-twenty-to-twenty-five: #ffa14d;
$margin-color-scale-twenty-five-to-thirty: #ffb56b;
$margin-color-scale-thirty-to-thirty-five: #ffc988;
$margin-color-scale-thirty-five-to-fourty: #ffd298;
$margin-color-scale-fourty-to-fourty-five: #ffd29a;
$margin-color-scale-fourty-five-to-fifty: #ffdbae;
$margin-color-scale-fifty-to-fifty-five: #fee2ce;
$margin-color-scale-fifty-five-to-sixty: #fee2c0;
$margin-color-scale-sixty-to-sixty-five: #f0dfc9;
$margin-color-scale-sixty-five-to-seventy: #ccd6d6;
$margin-color-scale-seventy-to-seventy-five: #b7d1dd;
$margin-color-scale-seventy-five-to-eighty: #9fcae5;
$margin-color-scale-eighty-to-eighty-five: #90c6ea;
$margin-color-scale-eighty-five-to-ninety: #7cc0f0;
$margin-color-scale-ninety-to-ninety-five: #74b5f3;
$margin-color-scale-ninety-five-to-one-hundred: #73acf4;
$margin-color-scale-one-hundred: #719ff5;
$margin-color_scale-21: #6E91F6;
$margin-color_scale-22: #6C80F7;






@mixin vertical-and-horizontal-center-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

@mixin vertical-align {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
}

@mixin hover-cursor-pointer {
    cursor: pointer;
}

@mixin stonex-bold-font {
    font-weight: 700;
    font-family: 'Mulish';
    color: $primary-text-color;
}

@mixin justify-and-align-flex-items-center {
    align-items: center;
    justify-content: center;
}

@mixin justify-align-with-text-center {
    align-items: center;
    justify-content: center;
    text-align: center;
}

@mixin media_wrapper_mobile {
    @media (min-width: 320px) and (max-width: 480px) {
        @content;
    }
}

@mixin media_wrapper_tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

// Table mixins.
@mixin common_table_header_text_spacing {
    font-size: 13px;
    line-height: 1.15;
    padding: 0px 18px;
    height: 55px;
    font-weight: bold;
}

@mixin common_table_header_display {
    display: flex;
    overflow: hidden;
    align-items: center;
}

@mixin common_table_borders {
    border-bottom: 1px solid $stonex-light-gray-blue;
    border-top: 1px solid $stonex-light-gray-blue;

    &_row_group:nth-child(odd),
    &_header_row div {
        background-color: $stonex-light-gray-white;
    }

    &_header_row div,
    &_cell,
    &_cell_large,
    &_actions_container {
        border-right: 1px solid $stonex-light-gray-blue;
    }

    &_header_row {
        border-bottom: 1px solid $stonex-light-gray-blue;
        display: flex;
    }
}

// For use in react components.
:export {
    fontFamily: $application-font-family;
    inputActiveState: $input-active-state;
    bold: $bold;
    expiredBlockColor: $expired-block-color;
    expiringBlockColor: $expiring-block-color;
    deactivatedBlockColor: $deactivated-block-color;
    pendingBlockColor: $pending-block-color;
    trialBlockColor: $trial-block-color;
    activeBlockColor: $active-block-color;
    plainWhite: $plainWhite;
    black: $black;
    mainTextColor: $primary-text-color;
    transparent: $transparent;
    stonexLightGrayBlue: $stonex-light-gray-blue;
    agGridLinkLolor: $stonex-blue-theme;
    stonexDarkGray: $stonex-dark-gray;
    stonexMediumGray: $stonex-medium-gray;
    snackbarGreen: $stonex-snackbar-green;
    stonexPrimaryBlue: $stonex-primary-blue;
    stonexSparklinePrimary: $stonex-sparkline-primary;
    stonexSkyBlue: $stonex-sky-blue;
    stonexSkyBlueTranslucent: $stonex-sky-blue-translucent;

    tableRowInvalid: $table-row-invalid;
    tableRowValid: $table-row-valid;
    tableRowDark: $table-row-dark;
    tableRowLight: $plainWhite;
    tableTextPositive: $stonex-positive-green;
    tableTextNegative: $expired-block-color;
    tableTextNegativeAlternative: $stonex-negative-red;

    chartYLabel: $stonex-dark-gray;
    chartBarColorAlt: $highchart-color-1;
    chartBarForecastColor1: $chart-bar-forecast-color-1;
    chartBarForecastColor2: $chart-bar-forecast-color-2;
    chartBarForecastColor3: $chart-bar-forecast-color-3;

    chartColor1: $chart-color-1;
    chartColor2: $chart-color-2;
    chartColor3: $chart-color-3;
    chartColor4: $chart-color-4;
    chartColor5: $chart-color-5;
    chartColor6: $chart-color-6;
    chartColor7: $chart-color-7;
    chartColor8: $chart-color-8;
    chartColor9: $chart-color-9;
    chartColor10: $chart-color-10;
    chartColor11: $chart-color-11;
    chartColor12: $chart-color-12;
    chartColor13: $chart-color-13;
    chartColor14: $chart-color-14;
    chartColor15: $chart-color-15;
    chartColor16: $chart-color-16;
    chartColor17: $chart-color-17;
    chartColor18: $chart-color-18;

    valueMatrixChartColor1: $value-matrix-chart-color-1;
    valueMatrixChartColor2: $value-matrix-chart-color-2;
    valueMatrixChartColor3: $value-matrix-chart-color-3;
    valueMatrixChartColor4: $value-matrix-chart-color-4;

    highchartColor1: $highchart-color-1;
    highchartColor2: $highchart-color-2;
    highchartColor3: $highchart-color-3;
    highchartColor4: $highchart-color-4;
    highchartColor5: $highchart-color-5;
    highchartColor6: $highchart-color-6;
    highchartColor7: $highchart-color-7;
    highchartColor8: $highchart-color-8;
    highchartColor9: $highchart-color-9;
    highchartColor10: $highchart-color-10;
    highchartColor11: $highchart-color-11;
    highchartColor12: $highchart-color-12;

    // Rule sets based off figma 'Chart Color Definition' page.
    chartAxisLabel: $stonex-dark-gray;
    // Rule Set 1 line chart colors.
    ruleSetOneLineColor1: $chart-color-1; 
    ruleSetOneLineColor2: $chart-color-2; 
    ruleSetOneLineColor3: $chart-color-3; 
    ruleSetOneLineColor4: $chart-color-5; 
    ruleSetOneLineColor5: $chart-color-18; 
    ruleSetOneLineColor6: $chart-color-19; 
    ruleSetOneLineColor7: $chart-color-7; 
    ruleSetOneLineColor8: $chart-color-8; 
    ruleSetOneLineColor9: $chart-color-9; 
    ruleSetOneLineColor10: $chart-color-10; 
    ruleSetOneLineColor11: $chart-color-11; 
    ruleSetOneLineColor12: $chart-color-12; 
    ruleSetOneLineColor13: $chart-color-13; 
    ruleSetOneLineColor14: $chart-color-14; 
    ruleSetOneLineColor15: $chart-color-15; 
    // Rule Set 1 bar chart colors.
    ruleSetOneBarColor1: $highchart-color-13;
    ruleSetOneBarColor2: $highchart-color-12;
    ruleSetOneBarColor3: $chart-color-1;
    // Rule Set 2 line chart colors.
    ruleSetTwoLineColor1: $chart-color-17;
    ruleSetTwoLineColor2: $chart-color-1;
    ruleSetTwoLineColor3: $chart-color-5;
    ruleSetTwoLineColor4: $chart-color-3;
    ruleSetTwoLineColor5: $chart-color-2;
    ruleSetTwoLineColor6: $chart-color-18;
    ruleSetTwoLineColor7: $chart-color-19;
    ruleSetTwoLineColor8: $chart-color-7;
    ruleSetTwoLineColor9: $chart-color-8;
    ruleSetTwoLineColor10: $chart-color-9;
    ruleSetTwoLineColor11: $chart-color-10;
    ruleSetTwoLineColor12: $chart-color-11;
    ruleSetTwoLineColor13: $chart-color-12;
    ruleSetTwoLineColor14: $chart-color-13;
    ruleSetTwoLineColor15: $chart-color-14;
    // Rule Set 2 price decile colors.
    ruleSetTwoPriceDecileNinetyToOneHundred: $ninety-to-one-hundred-decile;
    ruleSetTwoPriceDecileEightyToNinety: $eighty-to-ninety-decile;
    ruleSetTwoPriceDecileSeventyToEighty: $seventy-to-eighty-decile;
    ruleSetTwoPriceDecileSixtyToSeventy: $sixty-to-seventy-decile;
    ruleSetTwoPriceDecileFiftyToSixty: $fifty-to-sixty-decile;
    ruleSetTwoPriceDecileFourtyToFifty: $fourty-to-fifty-decile;
    ruleSetTwoPriceDecileThirtyToFourty: $thirty-to-fourty-decile;
    ruleSetTwoPriceDecileTwentyToThirty: $twenty-to-thirty-decile;
    ruleSetTwoPriceDecileTenToTwenty: $ten-to-twenty-decile;
    ruleSetTwoPriceDecileZeroToTen: $zero-to-ten-decile;
    ruleSetTwoPriceDecileAlphaNinetyToOneHundred: $ninety-to-one-hundred-decile-alpha;
    ruleSetTwoPriceDecileAlphaEightyToNinety: $eighty-to-ninety-decile-alpha;
    ruleSetTwoPriceDecileAlphaSeventyToEighty: $seventy-to-eighty-decile-alpha;
    ruleSetTwoPriceDecileAlphaSixtyToSeventy: $sixty-to-seventy-decile-alpha;
    ruleSetTwoPriceDecileAlphaFiftyToSixty: $fifty-to-sixty-decile-alpha;
    ruleSetTwoPriceDecileAlphaFourtyToFifty: $fourty-to-fifty-decile-alpha;
    ruleSetTwoPriceDecileAlphaThirtyToFourty: $thirty-to-fourty-decile-alpha;
    ruleSetTwoPriceDecileAlphaTwentyToThirty: $twenty-to-thirty-decile-alpha;
    ruleSetTwoPriceDecileAlphaTenToTwenty: $ten-to-twenty-decile-alpha;
    ruleSetTwoPriceDecileAlphaZeroToTen: $zero-to-ten-decile-alpha;
    // Rule Set 2 data decile colors.
    ruleSetTwoDataDecileColor1: $data-decile-1;
    ruleSetTwoDataDecileColor2: $data-decile-2;
    ruleSetTwoDataDecileColor3: $data-decile-3;
    ruleSetTwoDataDecileColor4: $data-decile-4;
    ruleSetTwoDataDecileColor5: $data-decile-5;
    ruleSetTwoDataDecileColorAlpha1: $data-decile-alpha-1;
    ruleSetTwoDataDecileColorAlpha2: $data-decile-alpha-2;
    ruleSetTwoDataDecileColorAlpha3: $data-decile-alpha-3;
    ruleSetTwoDataDecileColorAlpha4: $data-decile-alpha-4;
    ruleSetTwoDataDecileColorAlpha5: $data-decile-alpha-5;
    // Rule Set 2 area range color.
    ruleSetTwoAreaColor1: $alternative-neutral-color-alpha;
    ruleSetTwoAreaColor2: $bullish-color-alpha;
    // Rule Set 3 pie chart colors.
    ruleSetThreePieChartColor1: $highchart-color-1;
    ruleSetThreePieChartColor2: $highchart-color-2;
    ruleSetThreePieChartColor3: $highchart-color-3;
    ruleSetThreePieChartColor4: $highchart-color-4;
    ruleSetThreePieChartColor5: $highchart-color-5;
    ruleSetThreePieChartColor6: $highchart-color-6;
    ruleSetThreePieChartColor7: $highchart-color-7;
    ruleSetThreePieChartColor8: $highchart-color-8;
    ruleSetThreePieChartColor9: $highchart-color-9;
    ruleSetThreePieChartColor10: $highchart-color-10;
    ruleSetThreePieChartColor11: $highchart-color-11;

    unavailableColor: $inactive-block-color;
    veryBearishColor: $very-bearish-color;
    bearishColor: $bearish-color;
    neutralColor: $neutral-color;
    bullishColor: $bullish-color;
    veryBullishColor: $very-bullish-color;
    alternativeNeutralColor: $alternative-neutral-color;
    alternativeNeutralColorLevel2: $alternative-neutral-color-level-2;
    alternativeNeutralColorLevel3: $alternative-neutral-color-level-3;


    alternativeNeutralTextColor: $alternative-neutral-text-color;

    ninetyToOneHundredDecileChart: $ninety-to-one-hundred-decile-chart;
    eightyToNinetyDecileChart: $eighty-to-ninety-decile-chart;
    seventyToEightyDecileChart: $seventy-to-eighty-decile-chart;
    sixtyToSeventyDecileChart: $sixty-to-seventy-decile-chart;
    fiftyToSixtyDecileChart: $fifty-to-sixty-decile-chart;
    
    ninetyToOneHundredDecile: $ninety-to-one-hundred-decile;
    eightyToNinetyDecile: $eighty-to-ninety-decile;
    seventyToEightyDecile: $seventy-to-eighty-decile;
    sixtyToSeventyDecile: $sixty-to-seventy-decile;
    fiftyToSixtyDecile: $fifty-to-sixty-decile;
    fourtyToFiftyDecile: $fourty-to-fifty-decile;
    thirtyToFourtyDecile: $thirty-to-fourty-decile;
    twentyToThirtyDecile: $twenty-to-thirty-decile;
    tenToTwentyDecile: $ten-to-twenty-decile;
    zeroToTenDecile: $zero-to-ten-decile;
    decileMedianLine: $decile-median-line;

    // Margin colors.
    marginColorScale1: $margin-color-scale-zero-to-five;
    marginColorScale2: $margin-color-scale-five-to-ten;
    marginColorScale3: $margin-color-scale-ten-to-fifteen;
    marginColorScale4: $margin-color-scale-fifteen-to-twenty;
    marginColorScale5: $margin-color-scale-twenty-to-twenty-five;
    marginColorScale6: $margin-color-scale-twenty-five-to-thirty;
    marginColorScale7: $margin-color-scale-thirty-to-thirty-five;
    marginColorScale8: $margin-color-scale-thirty-five-to-fourty;
    marginColorScale9: $margin-color-scale-fourty-to-fourty-five;
    marginColorScale10: $margin-color-scale-fourty-five-to-fifty;
    marginColorScale11: $margin-color-scale-fifty-to-fifty-five;
    marginColorScale12: $margin-color-scale-fifty-five-to-sixty;
    marginColorScale13: $margin-color-scale-sixty-to-sixty-five;
    marginColorScale14: $margin-color-scale-sixty-five-to-seventy;
    marginColorScale15: $margin-color-scale-seventy-to-seventy-five;
    marginColorScale16: $margin-color-scale-seventy-five-to-eighty;
    marginColorScale17: $margin-color-scale-eighty-to-eighty-five;
    marginColorScale18: $margin-color-scale-eighty-five-to-ninety;
    marginColorScale19: $margin-color-scale-ninety-to-ninety-five;
    marginColorScale20: $margin-color-scale-ninety-five-to-one-hundred;
    marginColorScale21: $margin-color-scale-one-hundred;
    marginColorScale22: $margin-color_scale-21;
    marginColorScale23: $margin-color_scale-22;
}
